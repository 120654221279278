import React, { useEffect, useState } from "react";
import DataTable from "../components/EmailList/DataTable";
import { DELETE, GET } from "../helper/api_helper";
import {
  CAMPAIGN_PAGINATION_URL,
  DELETE_CAMPAIGN_URL,
} from "../helper/url_helper";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { globalContext } from "../contexts/DataContext";
import { PAGINATION } from "../config/constant";
import moment from "moment";

const Campaign = () => {
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState([]);
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE);
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  );
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { setViewCampaign, setLoading } = globalContext();
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (!isInitial || searchText) {
          fetchData();
        }
      },
      searchText ? 500 : 0
    );

    return () => clearTimeout(delayDebounceFn);
  }, [activepage, dataPerPage, searchText]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      fetchData();
    }
  }, [isInitial]);

  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };

  const fetchData = async () => {
    setLoading(true);
    const object = {
      limit: dataPerPage,
      page: activepage,
      searchText: searchText.trim(""),
    };

    const query = new URLSearchParams(object).toString();
    const responseData = await GET(`${CAMPAIGN_PAGINATION_URL}/?${query}`);
    setTotalData(responseData?.meta?.total);
    setLoading(false);
    const allData = {
      columns: [
        {
          label: "No",
          field: "no",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          width: 150,
        },
        {
          label: "Email List",
          field: "email_list_name",
          width: 150,
        },
        {
          label: "Template",
          field: "last_sent_template_name",
          width: 150,
        },
        {
          label: "Now",
          field: "GMT_Offset",
          width: 200,
        },
        {
          label: "Template",
          field: "last_sent_template_name",
          width: 150,
        },
        {
          label: "Date",
          field: "last_sent_date",
          width: 150,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: responseData.data.map((campaings, index) => {
        const position = (activepage - 1) * dataPerPage + index + 1;
        let GMT_Offset;
        const offset = campaings.email_list.GMT_Offset;
        if (typeof offset === "string" || typeof offset === "number") {
          GMT_Offset = moment().utcOffset(offset).format("DD/MM hh:m A");
        } else {
          GMT_Offset = "Invalid GMT Offset"; 
        }

        return {
          ...campaings,
          no: position,
          email_list_name: campaings.email_list.name,
          GMT_Offset,
          last_sent_template_name: campaings.last_sent_template,

          actions: (
            <>
              <button
                onClick={() => handleViewCampaign(campaings.id)}
                className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                style={{ color: "rgb(10, 54, 34, 0.5)" }}
              >
                <i className="fas fa-eye" title="View"></i>
              </button>
              <button
                onClick={() => handleEditCampaign(campaings.id)}
                className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                style={{ color: "rgb(10, 54, 34, 0.5)" }}
              >
                <i className="fas fa-edit" title="Edit"></i>
              </button>

              <button
                onClick={() => handleDeleteCampaign(campaings.id)}
                className="border-0 bg-transparent text-yellow fs-5 mr-2"
                style={{ color: "rgb(88, 21, 28, 0.5)" }}
              >
                <i className="fas fa-trash" title="Delete"></i>
              </button>
            </>
          ),
        };
      }),
    };
    setCampaignData(allData);
    setLoading(false);
  };

  //handle For View Campaign
  const handleViewCampaign = (campaignId) => {
    navigate(`/campaigns/actions/campaignhistory/${campaignId}`);
    setViewCampaign(true);
  };

  //handle For Edit Campaign
  const handleEditCampaign = (campaignId) => {
    navigate(`/campaigns/actions/${campaignId}`);
  };

  //handle For Delete Campaign
  const handleDeleteCampaign = async (campaignId) => {
    try {
      Swal.fire({
        title:
          "<span style='color: black; font-weight: bold;'>Are you sure?</span>",
        html: "<span style='color: black;'>You won't be able to revert this!</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "swal-confirm-button",
          cancelButton: "swal-cancel-button",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const responseData = await DELETE(
            `${DELETE_CAMPAIGN_URL}/${campaignId}`
          );
          if (Number(responseData.meta.status) === 200) {
            fetchData();
            toast.success(responseData?.meta?.message);
          }
        }
      });
    } catch (error) {
      toast.error(error?.response?.data?.meta?.message);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-end mb-5">
            <button
              className="bg-primary border-0 rounded-pill px-4 "
              style={{ height: "2.5rem", color: "white" }}
              onClick={() => navigate(`/campaigns/actions`)}
            >
              Add
            </button>
          </div>
          <DataTable
            title={""}
            limit={dataPerPage}
            totalRecords={totalData}
            tableData={campaignData}
            handleActivePageChange={handleActivePageChange}
            handleSearchChange={handleSearchChange}
            handleEnteriesChange={handleEnteriesChange}
            text={"Campaign"}
            loading={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Campaign;
