import React, { useEffect, useState } from "react";
import DataTable from "../components/EmailList/DataTable";
import Swal from "sweetalert2";
import "../components/EmailList/EmailPagination.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  EMAIL_LIST_DELETE_URL,
  VIEW_ALL_EMAIL_LIST,
  EMAIL_LIST_PAGINATION_URL,
} from "../helper/url_helper";
import { DELETE, GET } from "../helper/api_helper";
import { globalContext } from "../contexts/DataContext";
import { PAGINATION } from "../config/constant";
import moment from "moment";


const EmailPagination = () => {
  const navigate = useNavigate();
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE);
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  );
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const {
    emailListData,
    setEmailListData,
    setViewEmailList,
    setLoading,
    setEmailListId,
  } = globalContext();

  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };

  const fetchData = async () => {
    setLoading(true);
    const object = {
      limit: dataPerPage,
      page: activepage,
      searchText: searchText.trim(""),
    };
    const query = new URLSearchParams(object).toString();
    const responseData = await GET(`${EMAIL_LIST_PAGINATION_URL}/?${query}`);
    setTotalData(responseData?.meta?.total);
    setLoading(false);
    const allData = {
      columns: [
        { label: "No", field: "no", width: 150 },
        { label: "Name", field: "name", width: 150 },
        { label: "Now", field: "GMT_Offset", width: 150 },
        { label: "Date", field: "created_date", width: 150 },
        { label: "TotalEmails", field: "total_emails_count", width: 150 },
        { label: "Bounced Emails", field: "total_bounced_count", width: 150 },
        { label: "Actions", field: "actions", sort: "disabled", width: 100 },
      ],
      rows: responseData.data.map((emails, index) => {
        const position = (activepage - 1) * dataPerPage + index + 1;
        let GMT_Offset;
        const offset = emails.GMT_Offset;
        if (typeof offset === "string" || typeof offset === "number") {
          GMT_Offset = moment().utcOffset(offset).format("DD/MM hh:m A");
        } else {
          GMT_Offset = "Invalid GMT Offset"; 
        }
        return {
          ...emails,
          no: position,
          GMT_Offset,
          email_count: new Intl.NumberFormat().format(emails.email_count),
          bounced_email_count: new Intl.NumberFormat().format(
            emails.bounced_email_count
          ),
          actions: (
            <>
              <button
                onClick={() => handleViewEmails(emails.id)}
                className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                style={{ color: "rgba(10, 54, 34, 0.5)" }}
              >
                <i className="fas fa-eye" title="View"></i>
              </button>
              <button
                onClick={() => handleEmailListEdit(emails.id)}
                className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                style={{ color: "rgba(10, 54, 34, 0.5)" }}
              >
                <i className="fas fa-edit" title="Edit"></i>
              </button>
              <button
                onClick={() => handleEmailDelete(emails.id)}
                className="border-0 bg-transparent text-yellow fs-5"
                style={{ color: "rgba(88, 21, 28, 0.5)" }}
              >
                <i className="fas fa-trash" title="Delete"></i>
              </button>
            </>
          ),
        };
      }),
    };
    setEmailListData(allData);
    setLoading(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText, activepage, dataPerPage])

  // Email Edit Handle Function
  const handleEmailListEdit = (emaillistId) => {
    navigate(`/emaillist/update`);
    setEmailListId(emaillistId);
  };

  // Email View Handle Function
  const handleViewEmails = async (emaillistId) => {
    navigate(`/emaillist/view`);
    setEmailListId(emaillistId);
    const object = {
      limit: dataPerPage,
      page: activepage,
      searchText: searchText.trim(""),
    };
    const query = new URLSearchParams(object).toString();
    try {
      const data = await GET(`${VIEW_ALL_EMAIL_LIST}/${emaillistId}?${query}`);
      setViewEmailList(data);
    } catch (error) {
      console.error("Error fetching email data:", error);
    }
  };


  const handleEmailDelete = async (email) => {
    try {
      const result = await Swal.fire({
        title:
          "<span style='color: black; font-weight: bold;'>Are you sure?</span>",
        html: "<span style='color: black;'>You won't be able to revert this!</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "swal-confirm-button",
          cancelButton: "swal-cancel-button",
        },
      });
      if (result.isConfirmed) {
        const responseData = await DELETE(`${EMAIL_LIST_DELETE_URL}/${email}`);
        if (Number(responseData.meta.status) === 200) {
          fetchData(); // Refresh data or UI
          toast.success(
            responseData?.meta?.message || "Email list deleted successfully."
          );
        } else {
          const errorMessage =
            responseData?.meta?.message || "Error deleting email list.";
          toast.error(errorMessage);
        }
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.meta?.message || "An error occurred.";
      if (error?.response?.data?.meta?.status === 400) {
        toast.error(
          "Email list cannot be deleted because it is in use by one or more campaigns."
        );
      } else if (error?.response?.data?.meta?.status === 500) {
        toast.error("Server error. Please try again later.");
      } else {
        toast.error(errorMessage);
      }
    } finally {
      console.log("Deletion process complete.");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <div className="d-flex justify-content-end mb-3">
            <button
              className="btn btn-primary rounded-pill px-4 h-10 text-white"
              onClick={() => {
                navigate("/fileupload");
              }}
            >
              Add
            </button>
          </div>
          <DataTable
            title=""
            limit={dataPerPage}
            totalRecords={totalData}
            tableData={emailListData}
            handleActivePageChange={handleActivePageChange}
            handleSearchChange={handleSearchChange}
            handleEnteriesChange={handleEnteriesChange}
            text={"EmailList"}
            loading={false}
            type="EMAILIST"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailPagination;
